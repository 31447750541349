<template>
  <div class="inner-container " :class="{ 'min-container': !getTabs }">
   <devisLibre></devisLibre>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import devisLibre from '../../components/ui/devisLibre.vue';
export default {
  components: {
    devisLibre
  },
     computed: {
    ...mapGetters([
      "getTabs",])},
}
</script>
<style scoped>
.inner-container .content {
  margin: 10px 15px;
  background-color: #fff;
  box-shadow: 1px 1px 24px #00000019;
  border-radius: 5px;
  padding: 14px;
  min-height: 88vh;
  margin-top: 55px;
}
</style>